$primary: #132238;
$primary-darker: #162236;
$footer-background-color: $primary;
$input-placeholder-color: #FFF;
$body-color: $primary-darker;
@import 'node_modules/bulma/bulma.sass';
@import 'spacing.scss';
// Helpers
.is-nunito {
    font-family: 'Nunito', sans-serif !important;
}
.is-ubuntu {
    font-family: 'Ubuntu', sans-serif !important;
}
body {
    font-family: 'Nunito', sans-serif;
}
h2.section-subtitle {
    font-size: 2.5em;
    font-weight: bold;
}
h3.section-subtitle {
    font-size: 1em;
    font-weight: bolder;
}
p {
    font-size: 1rem;
    line-height: 2em;
}
.title-header {
    background-size: cover;
    background-repeat: no-repeat;
    height: 365px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
section:not(:first-child) {
    margin-top: 5em;
}
section:not(:last-child):after {
    margin-bottom: 10em;
}
.angled-bottom {
    // content: '';
    // position: absolute;
    // bottom: 0;
    // right: 0;
    // width: 0;
    // height: 0;
    // border-style: solid;
    // border-width: 0 0 25px 25px;
    // border-color: transparent transparent #F8F8F8 transparent;
}
.has-bg-img {
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
}
.button-svg {
    display: inline-block;
    width: 200px;
    text-align: center;
    line-height: 65px;
    font-size: 1.5em;
    color: $primary;
    background-color: transparent;
    border: 0;
    &:hover {
        color: $white;
        background-color: $primary;
    }
    svg {
        width: 200px;
        position: absolute;
        left: 0;
    }
}
.button-svg-reverse {
    display: inline-block;
    width: 200px;
    text-align: center;
    line-height: 65px;
    font-size: 1.5em;
    color: $white;
    background-color: transparent;
    border: 0;
    &:hover {
        color: $primary;
        background-color: $white;
    }
    svg {
        width: 200px;
        position: absolute;
        left: 0;
    }
}
.button.is-primary {
    border: 2px solid $primary;
    padding: 1em 2em;
    font-size: 1.5em;
    background-color: transparent;
    border-radius: unset;
    color: $primary;
    font-family: 'Ubuntu', sans-serif;
}
.button.is-white {
    border: 2px solid $white;
    font-size: 1.3em;
    background-color: transparent;
    color: $white;
    text-transform: uppercase;
    font-family: 'Ubuntu', sans-serif;
    padding: .5em 2em;
    height: 100%;
    border-radius: unset;
}
.border-left {
    border-left: 1px solid $white;
    padding-left: 2em !important;
}
.border-left-primary {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1px;
        height: 30%;  /* or 100px */
        border-left: 2px solid $primary;
        top: 1em;
    }
}
.download {
    width: 25px;
}
.has-background-primary-darker {
    background-color: $primary-darker;
}
hr {
    background-color: $primary;
    width: 40%;
    z-index: 1;
    position: absolute;
    &.hr-left {
        left: -30%;
        top: 4%;
    }
    &.hr-right {
        right: -25%;
        top: 4%;
    }
    @include mobile {
        display: none;
    }
}
.reverse-mobile {
    @include mobile {
        display: flex;
        flex-direction: column-reverse;
    }
    > .p-r-xl {
         @include mobile {
             padding-right: 1rem;
             padding-left: 4rem;
         }   
    }
}

// Logos
.navbar-brand {
    img {
        @include mobile {
            max-width: 100px;
        }
    }
}
.about-stamp {
    position: absolute;
    width: 435px;
    top: -8em;
    left: -6em;
    z-index: 1;
}
.about-text {
    padding-top: calc(5vw - 70px);
    position: absolute;
    z-index: 1;
}

.hero {
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 100%;
    padding-bottom: 5em;
    @include widescreen {
        padding-bottom: 10em;
    }
    @include fullhd {
        padding-bottom: 11em;
    }
    .container {
        max-width: unset;
    }
    @include mobile {
        background-size: cover;
        min-width: unset;
        padding-bottom: unset;
        background-position: center 100%;
        height: 100vh;
    }
}
.hero-body {
    padding-top: 30%;
    @include mobile {
        margin-top: -5em;
    }
}
.social-links {
    width: 30px;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .vl-social {
        border-left: 2px solid $primary;
        width: 1px;
        top: 50px;
        padding-top: 100px;
        margin-top: 15px;
        margin-left: 14px;
    }
}
.social {
    border: 1px solid $primary;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 2px;
    > * {
        color: $primary;
    }
}

.vl-map {
    border-left: 2px solid $primary;
    height: 100px;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 1px;
    top: -3em;
}
.bottom-logo {
    position: absolute;
    bottom: -3em;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
}
.menu-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
}
.logo-with-text {
    background-image: url('/assets/main/img/aurmonstamp1.svg');
    background-repeat: no-repeat;
    background-position: 39%;
    background-size: 30%;
    @include mobile {
        background-position: -5% 80%;
        background-size: 50%;
    }
    .text-right {
        h2 {
            font-size: 2.7rem;
            font-style: italic;
        }
        p {
            border-left: 1px solid $primary;
            padding-left: 4em;
            margin-top: 2em;
            @include mobile {
                border-left: 0;
            }
        }
    }
}

// About
#about {
    margin-top: 10em;
    @include widescreen {
        margin-top: 15em;
    }
}
.about {
    @include mobile {
        background-color: $primary;
        height: 90vh;
        .is-overlay {
            padding: 2em;
        }
        img {

        }
    }
}
.background-image {
    background-image: url('/assets/main/img/blueslanted-about.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: calc(50vw);
}

.feature {
    min-height: 900px;
    margin-top: -30vh;
    @include mobile {
        margin-top: 0;
    }
    .w2-text {
        background: rgba(255,255,255,.8);
        height: 100%;
        padding-top: 18em;
        padding-left: 5em;
        padding-right: 17em;
        padding-bottom: 8em;
        @include mobile {
            padding-top: 10em;
            padding-right: 5em;
        }
        h2 {
            font-size: 2em;
            font-weight: bolder;
        }
    }
}

#projects {
    margin-top: 5em;
    img {
        min-height: 100%;
    }
    .logo {
        position: absolute;
        top: 10%;
        left: 10%;
        width: 150px;
        min-height: unset;
        height: 100px;
    }
    .coming-soon-img {
        min-height: 650px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $white;
        text-transform: uppercase;
        font-family: 'Ubuntu', sans-serif;
        letter-spacing: .2em;
    }
    .coming-soon {
        text-align: left !important;
        > span {
            display: block;
            text-align: left !important;
            text-transform: uppercase;
            font-size: .5em;
            font-weight: normal;
        }
    }
    .is-overlay {
        color: $white;
        text-align: center;
        font-weight: bold;
        font-family: 'Ubuntu', sans-serif;
        letter-spacing: .2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    h2 {
        margin-bottom: 2%;
    }
    .address {
        margin-bottom: 2em;
    }
    .button {
        margin-top: 2em;
        font-size: 1em;
    }
    .building-info {
        @include mobile {
            padding-left: 1rem;
        }
        .column {
            padding: .2rem;
        }
    }
    .number {
        margin-right: unset;
        background-color: unset;
        color: $primary;
        font-size: 1.8rem;
        height: 1em;
        font-weight: lighter;
    }
    .description {
        padding-left: 8rem;
        padding-right: 8rem;
        @include mobile {
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }
}
.vl {
    border-left: 2px solid $primary;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 1px;
    top: 50px;
    height: 100px;
}
.contact-us {
    background-position: 50% 100%;
    margin-top: 10em;
    > #form {
        padding: 5em 7em;
        margin-top: -14em;
        margin-bottom: 8em;
        z-index: 1;
        @include mobile {
            margin-top: -5em;
            padding: 5em 2em;;
        }
    }
    .form {
        padding: 2em;
        h2 {
            font-size: 2em;
            font-weight: bold;
            font-family: 'Ubuntu', sans-serif;
        }
        .column {
            padding: 0;
        }
        .input {
            box-shadow: unset;
            border: unset;
            border-bottom: 1px solid $white;
            background-color: unset;
            border-radius: unset;
            color: $white;
        }
        .textarea {
            box-shadow: unset;
            border: 1px solid $white;
            background-color: unset;
            border-radius: unset;
            color: $white;
            resize: none;
        }
        input::placeholder, input::-webkit-input-placeholder,
        input::-moz-placeholder, input:-moz-placeholder,
        textarea::placeholder {
            color: $white;
            opacity: 1;
            font-family: 'Nunito', sans-serif;
            font-size: .8em;
            font-style: italic;
        }
    }
}

#convenience {
    margin-bottom: 7em;
}

.footer {
    .social {
        border: 1px solid $white;
        margin-left: .5em;
        margin-right: .5em;
    }
    .level-item {
        p {
            line-height: 3em;
        }
    }
}

.download-link {
    display: block;
    border: 1px solid $primary;
    padding: 1em;
    color: $primary;
    img {
        float: right;
    }
}

.w2-about {
    margin-top: 0px;
}

.w2-bg {
    position: absolute;
    width: 100%;
    z-index: -1;
    top: -35%;
    height: 150%;
    @include mobile {
        width: unset;
        max-width: unset;
        top: -11%;
        height: 120%;
        left: -210%;
    }
}

.rectangle-img {
    background-size: cover;
    background-position: 50% 35%;
    height: 100%;
    min-height: 400px;
}

// Modal
.modal-background {
    background-color: #162236;
    background-image: url('/assets/main/img/aurmonstamp-menu.svg');
    background-position: center bottom;
    background-size: 600px;
    background-repeat: no-repeat;
}
.navbar {
    > .container .navbar-brand {
        margin-left: 2.5rem;
    }
}
.navbar-item {
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    height: 5em;
    @include mobile {
        height: 3em;
    }
}
a.navbar-item:hover {
    background-color: unset;
    color: white;
    text-decoration: underline;
}
.navbar-link:hover {
    background-color: unset;
    color: white;
    text-decoration: underline;
}
.navbar-end {
    margin-right: 2.5rem;
}

// SVG styles
@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }
    to {
        stroke-dashoffset: 0;
    }
}

// Scroll to Top
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 99;
}
#return-to-top i {
    color: $white;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}
#return-to-top:hover i {
    color: $white;
    top: 5px;
}